import React from 'react';
import whyBlog from '../static/blogImages/whyBlog.png';

const About = () => {
  return (
    <div className="about">
      <h2>About Us</h2>
      <div className="about-content">
        <img src={whyBlog} alt="Blog Team" className="about-photo" />
        <div className="about-text">
          <h3>Our Mission</h3>
          <p>This blog is dedicated to bringing a little happiness to the lives of our readers. We believe in the power of entertainment and storytelling to brighten people's days.</p>
          <h3>Our Vision</h3>
          <p>We are committed to becoming the number one blog in the world, providing engaging and uplifting content that resonates with a global audience.</p>
        </div>
      </div>
    </div>
  );
};

export default About;