import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './components/Header';
import Blog from './components/Blog';
import Footer from './components/Footer';
import PostDetail from './components/PostDetail';
import NotFound from './components/NotFound';
import About from './components/About';
import BlogApi from './static/blogApi';
import Contact from './components/Contact';
import './App.css';


const App = () => {
  return (
    <Router>
      <div className="app">
        <Header />
        <nav className="nav">
          <h3>Latest posts</h3>
          <ul>
            {BlogApi.slice(-3).map((BlogApi) => (
              <li key={BlogApi.id}>
                <Link to={`/post/${BlogApi.id}`}>{BlogApi.title}</Link>
              </li>
            ))}
          </ul>
        </nav>
        <Routes>
          <Route path="/" exact element={<Blog posts={BlogApi} />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/post/:postId" element={<PostDetail posts={BlogApi} />} />
          <Route path="*" element={<NotFound posts={BlogApi} />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
