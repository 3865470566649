import React from 'react';
import { useParams, Link } from 'react-router-dom';
import '../styles/PostDetail.css';

function scrollUp() {

  let currentScroll = document.documentElement.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(scrollUp);
    window.scrollTo(0, currentScroll - (currentScroll / 27));
  }
}

const PostDetail = ({ posts }) => {

  const { postId } = useParams();
  const post = posts.find((post) => post.id === postId);

  // Select three recommended posts (you can manually choose them)
  const recommendedPosts = [
    posts[1], // Second post
    posts[3], // Fourth post
    posts[5], // Sixth post
  ];

  return (
    <div className="post-detail">
      <div className="ad ad-post-bottom">
        {/* ... (recommended posts section) */}
      </div>
      {post && (
        <article className="post">
          <h2>{post.title}</h2>
          <div className="post-content">
            <p className="post-contentSumari">{post.content.contentSumari}</p>
            <img
              src={post.imageTitle}
              alt={post.postId}
              className="post-photo"
            />
          </div>

          <div className="post-info">
            <p className="author">By {post.author}</p>
            <p className="date">{post.date}</p>
          </div>
        </article>
      )}
      <div className="ad ad-post-right">
        {/* ... (recommended posts section) */}
      </div>
      <div className="recommended-posts">
        {/* ... (recommended posts section) */}
      </div>
      <div className="recommended-posts">
        <h3>Recommended Posts</h3>
        <div className="recommended-list">
          {recommendedPosts.map((recommendedPost) => (
            <div key={recommendedPost.id} className="recommended-post">
              <h4>{recommendedPost.title}</h4>
              <p>{recommendedPost.content.contentSumari.substring(0, 80)}...</p>
              <Link to={`/post/${recommendedPost.id}`} onClick={scrollUp}>Read More</Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PostDetail;