import image1 from './blogImages/image1id1.png'

const posts = [
    {
      id: 'post1',
      title: 'The 5 Best-Selling Items on Amazon Right Now',
      imageTitle: image1,
      content:{
        contentSumari: "Amazon is the world's largest online marketplace, offering a vast array of products across every conceivable category. Keeping up with the latest best-sellers can help you find the hottest items that everyone is buying. Here are the five best-selling items on Amazon right now.",
        content1:'content 1',
        content2:'content 2',
        content3:'content 3',
        content4:'content 4',
        content5:'content 5',
      },
      author: 'chatGPT',
      date: 'September 9, 2024',
    },
    {
      id: 'post2',
      title: 'Post 2',
      imageTitle: image1,
      content:{
        contentSumari: "Amazon is the world's largest online marketplace, offering a vast array of products across every conceivable category. Keeping up with the latest best-sellers can help you find the hottest items that everyone is buying. Here are the five best-selling items on Amazon right now.",
        content1:'content 1',
        content2:'content 2',
        content3:'content 3',
        content4:'content 4',
        content5:'content 5',
      },
      author: 'Author 2',
      date: 'August 26, 2023',
    },
    {
      id: 'post3',
      title: 'Post 3',
      imageTitle: image1,
      content:{
        contentSumari: "Amazon is the world's largest online marketplace, offering a vast array of products across every conceivable category. Keeping up with the latest best-sellers can help you find the hottest items that everyone is buying. Here are the five best-selling items on Amazon right now.",
        content1:'content 1',
        content2:'content 2',
        content3:'content 3',
        content4:'content 4',
        content5:'content 5',
      },
      author: 'Author 3',
      date: 'August 26, 2023',
    },
    {
      id: 'post4',
      title: 'Post 4',
      imageTitle: image1,
      content:{
        contentSumari: "Amazon is the world's largest online marketplace, offering a vast array of products across every conceivable category. Keeping up with the latest best-sellers can help you find the hottest items that everyone is buying. Here are the five best-selling items on Amazon right now.",
        content1:'content 1',
        content2:'content 2',
        content3:'content 3',
        content4:'content 4',
        content5:'content 5',
      },
      author: 'Author 1',
      date: 'August 25, 2023',
    },
    {
      id: 'post5',
      title: 'Post 5',
      imageTitle: image1,
      content:{
        contentSumari: "Amazon is the world's largest online marketplace, offering a vast array of products across every conceivable category. Keeping up with the latest best-sellers can help you find the hottest items that everyone is buying. Here are the five best-selling items on Amazon right now.",
        content1:'content 1',
        content2:'content 2',
        content3:'content 3',
        content4:'content 4',
        content5:'content 5',
      },
      author: 'Author 2',
      date: 'August 26, 2023',
    },
    {
      id: 'post6',
      title: 'Post 6',
      imageTitle: image1,
      content:{
        contentSumari: "Amazon is the world's largest online marketplace, offering a vast array of products across every conceivable category. Keeping up with the latest best-sellers can help you find the hottest items that everyone is buying. Here are the five best-selling items on Amazon right now.",
        content1:'content 1',
        content2:'content 2',
        content3:'content 3',
        content4:'content 4',
        content5:'content 5',
      },
      author: 'Author 6',
      date: 'August 26, 2023',
    },
  ];

  export default posts;