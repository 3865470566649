import React from 'react';
import { Link } from 'react-router-dom';


function scrollUp() {
  
  let currentScroll = document.documentElement.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(scrollUp);
    window.scrollTo(0, 0);
  }
}

const PostPreviewCard = ({ post }) => {
  return (
    <div className="post-preview">
      <h3>{post.title}</h3>
      <img src={post.imageTitle} alt={post.postId} className="post-photo" />
      <p className="post-excerpt">
        {post.content.contentSumari.substring(0, 150)}...
      </p>
      <div className="post-info">
        <p className="author">By {post.author}</p>
        <p className="date">{post.date}</p>
      </div>
      <Link to={`/post/${post.id}`} onClick={scrollUp} >Read More</Link>
      <div className="ad ad-card">{/* ... (recommended posts section) */}</div>
    </div>
  );
};

const Blog = ({ posts }) => {
  return (
    <main>
      <div className="post-list">
        {posts.map((post, index) => (
          <PostPreviewCard key={index} post={post} />
        ))}
      </div>
    </main>
  );
};

export default Blog;
